<template>
    <div class="raydata-container">
        <div class="raydata-bg"></div>
        <div class="raydata-body-title" :style="[baseDataInfo.FontSize>0?{fontSize:baseDataInfo.FontSize+'px'}:{}]">{{ baseDataInfo.Title }}</div>

        <div class="raydata-head-time">{{ timeStr }}</div>

        <div class="full-screen" style="display:flex;width:auto;align-items:center;">
            <div style="margin-right:10px;display:flex;align-items:center;">
                <i
                    :class="showWeatherIcon"
                    style="font-size: 45px; margin-right: 10px"
                ></i>
                <div class="weathertime-info">
                    <div v-if="weatherData">
                        温度 {{ weatherData.wd }} {{ weatherData.sd }}
                    </div>
                    <div v-if="weatherData">
                        {{ weatherData.tq }} {{ weatherData.fl }}
                    </div>
                </div>
            </div>
            <img
                src="@/assets/raydata/icon-full-screen.png"
                @click="onFullScreen"
            />
        </div>
        

        <div class="raydata-body">
            <div class="flex-block" style="margin-bottom: 20px">
                <el-row class="row-block" :gutter="20">
                    <el-col :span="6" class="row-block">
                        <main-left
                            :notice="baseDataInfo.Desc"
                            :bigcode="bigcode"
                            :waring-data="waringData&&waringData.waringlist"
                        ></main-left>
                    </el-col>
                    <el-col :span="12" class="row-block">
                        <main-center
                            :bigcode="bigcode"
                            :init-data="baseDataInfo"
                            :device-data="deviceData"
                        ></main-center>
                    </el-col>
                    <el-col :span="6" class="row-block">
                        <main-right
                            :bigcode="bigcode"
                            :device-data="deviceData"
                        ></main-right>
                    </el-col>
                </el-row>
            </div>
            <div
                class="raydata-footer"
                style="height: 26%; margin-bottom: 20px"
            >
                <el-row class="row-block" :gutter="20">
                    <el-col class="row-block" :span="12">
                        <footer-left
                            :bigcode="bigcode"
                            :device-data="deviceData"
                            :device-ratio="deviceRatio"
                        ></footer-left>
                    </el-col>
                    <el-col class="row-block" :span="12">
                        <footer-right
                            :bigcode="bigcode"
                            :device-data="deviceData"
                            :waring-data="waringData&&waringData.waring"
                        ></footer-right>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
@import "../../style/weather-icons/qweather-icons.css";
</style>
<script>
import Vue from "vue";
import MainLeft from "./components/main-left.vue";
import MainRight from "./components/main-right.vue";
import MainCenter from "./components/main-center.vue";
import FooterLeft from "./components/footer-left.vue";
import FooterRight from "./components/footer-right.vue";

import { initData, getDeviceInfo,getWaringInfo,getDerviceRatio,getWeather } from "@/api/raydata";
export default {
    data() {
        return {
            fullscreen: 0,
            bigcode: 0,
            baseDataInfo: "",
            deviceData: null,
            deviceRatio:"",
            waringData: "",
            timeStr: "",
            weatherData:"",//天气数据
        };
    },
    components: {
        MainLeft,
        MainRight,
        MainCenter,
        FooterLeft,
        FooterRight,
    },
    
    computed: {
        showWeatherIcon: function () {
            if (this.weatherData) {
                if (this.weatherData.tq == "晴") {
                    return "qi-101";
                } else if (
                    this.weatherData.tq == "阴" ||
                    this.weatherData.tq == "多云"
                ) {
                    return "qi-104";
                } else if (this.weatherData.tq.indexOf("雨") >= 0) {
                    return "qi-309";
                } else if (this.weatherData.tq.indexOf("雪") >= 0) {
                    return "qi-408";
                } else {
                    return "";
                }
            }
            return "";
        },
    },
    methods: {
        onFullScreen() {
            if (this.fullscreen == 0) {
                this.fullscreen = 1;
                var el = document.documentElement;
                var rfs =
                    el.requestFullScreen ||
                    el.webkitRequestFullScreen ||
                    el.mozRequestFullScreen ||
                    el.msRequestFullScreen;
                if (typeof rfs != "undefined" && rfs) {
                    rfs.call(el);
                } else if (typeof window.ActiveXObject != "undefined") {
                    //for IE，这里其实就是模拟了按下键盘的F11，使浏览器全屏
                    var wscript = new ActiveXObject("WScript.Shell");
                    if (wscript != null) {
                        wscript.SendKeys("{F11}");
                    }
                }
            } else {
                this.fullscreen = 0;
                var el = document;
                var cfs =
                    el.cancelFullScreen ||
                    el.webkitCancelFullScreen ||
                    el.mozCancelFullScreen ||
                    el.exitFullScreen;
                if (typeof cfs != "undefined" && cfs) {
                    cfs.call(el);
                } else if (typeof window.ActiveXObject != "undefined") {
                    //for IE，这里和fullScreen相同，模拟按下F11键退出全屏
                    var wscript = new ActiveXObject("WScript.Shell");
                    if (wscript != null) {
                        wscript.SendKeys("{F11}");
                    }
                }
            }
            this.getData();
        },
        getData() {
            getDeviceInfo(this.bigcode).then((res) => {
                if (res.code == 0) {
                    this.deviceData = res.data;
                    if(res.data.hiddanger.length>0){
                        getDerviceRatio(this.bigcode,res.data.hiddanger[0].hid).then(res2=>{
                            if(res2.code==0){
                                this.deviceRatio = res2.data;
                            }
                        })
                    }
                }
            });
            getWaringInfo(this.bigcode).then((res) => {
                if (res.code == 0) {
                    this.waringData = res.data;
                }
            });
            initData(this.bigcode).then((res) => {
                if (res.code == 0) {
                    this.baseDataInfo = res.data;
                }
            });

            getWeather(this.bigcode).then(res=>{
                if(res.code==0){
                    this.weatherData = res.data;
                }
            })
        },
    },
    mounted() {
        this.getData();
    },
    created() {
        var bigcode = this.$route.query.bigcode;
        this.bigcode = bigcode;

        var dayarr = [
            "星期日",
            "星期一",
            "星期二",
            "星期三",
            "星期四",
            "星期五",
            "星期六",
        ];
        var daystr = dayarr[new Date().getDay()];
        this.timeStr =
            Vue.prototype.$formatDate(new Date(), "yyyy-MM-dd hh:mm:ss") +
            " " +
            daystr;
        setInterval(() => {
            var dayarr = [
                "星期日",
                "星期一",
                "星期二",
                "星期三",
                "星期四",
                "星期五",
                "星期六",
            ];
            var daystr = dayarr[new Date().getDay()];
            this.timeStr =
                Vue.prototype.$formatDate(new Date(), "yyyy-MM-dd hh:mm:ss") +
                " " +
                daystr;
        }, 1000);
    },
};
</script>