<template>
  <div class="ray-border-box3">
    <el-row class="row-block" :gutter="20">
      <el-col class="row-block" :span="14">
        <div class="ray-box-title">设备回传、预警走势</div>
        <div style="height:100%;">
          <chart-bar-trend id="deviceDataTotalCount" :data="deviceDataTotalCount" style="width:100%;height:100%;"></chart-bar-trend>
        </div>
      </el-col>
      <el-col class="row-block" :span="10">
        <div class="ray-box-title">设备占比</div>
        <div style="height:100%;">
          <chart-pie-device id="deviceTotalRatio" :data="deviceTotalData" style="width:100%;height:100%;"></chart-pie-device>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<style lang="scss">
</style>
<script>
import chartPieDevice from './chart-pie-device'
import chartBarTrend from './chart-bar-trend'
import {getDeviceDataInfo} from '@/api/raydata'
export default {
  data(){
    return {
      deviceDataTotalCount:[],
      deviceTotalData:[],
    }
  },
  components:{
    chartPieDevice,
    chartBarTrend
  },
  props: {
    bigcode: {
      type: String,
    },
    deviceData: {
      type: [String,Object],
    },
    deviceRatio:{
      type: [String,Object],
    },
  },
  mounted(){
    if(this.bigcode){
      this.getData();
    }
  },
  methods: {
    handleData(){
      var hiddanger = this.deviceData.hiddanger;
      var deviceTotal = [];
      var findDevice = function(name){
        var nowindex = -1;
        for (let index = 0; index < deviceTotal.length; index++) {
          const element = deviceTotal[index];
          if(element.name == name){
            nowindex = index;
            break;
          }
        }
        return nowindex;
      }
      hiddanger.forEach(element => {
        element.deviceList.forEach(item => {
          var index = findDevice(item.title);
          if(index>-1){
            deviceTotal[index].value = deviceTotal[index].value+item.count;
          }else{
            deviceTotal.push({
              name: item.title,
              value: item.count,
            })
          }
        });
      });
      this.deviceTotalData = deviceTotal;
    },
    getData(){
      getDeviceDataInfo(this.bigcode).then(res=>{
        if(res.code==0){
          this.deviceDataTotalCount = res.data.datalist;
        }
      })
    }
  },
  watch:{
    bigcode(){
      this.getData();
    },
    deviceData:{
      deep:true,
      handler(){
        this.handleData();
      }
    }
  }
};
</script>