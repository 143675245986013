import request from '@/common/request'

/**
 * 获取基本信息
 * @param {*} bigcode 
 */
export function initData(bigcode) {
    return request.post('/api/WisdomScreen/Init', '', 'json', {
        bigcode: bigcode
    });
}

/**
 * 获取设备列表信息
 * @param {*} bigcode 
 */
export function getDeviceInfo(bigcode) {
    return request.post('/api/WisdomScreen/BaseDeviceInfo', '', 'json', {
        bigcode: bigcode
    });
}

/**
 * 获取预警信息
 * @param {*} bigcode 
 */
export function getWaringInfo(bigcode) {
    return request.post('/api/WisdomScreen/DeviceWaringInfo', '', 'json', {
        bigcode: bigcode
    });
}

/**
 * 获取设备走势信息
 * @param {*} bigcode 
 */
export function getDeviceDataInfo(bigcode) {
    return request.post('/api/WisdomScreen/DeviceDataInfo', '', 'json', {
        bigcode: bigcode
    });
}

/**
 * 获取设备占比信息
 * @param {*} bigcode 
 */
export function getDerviceRatio(bigcode, hiddangerid) {
    return request.post('/api/WisdomScreen/MonitorDevice?hiddangerid=' + hiddangerid, '', 'json', {
        bigcode: bigcode
    });
}


export function getMapArea(areaname) {
    return request.get('/content/province/' + areaname, '', 'json', {});
}

/**
 * 获取天气情况
 * @param {*} bigcode 
 * @returns 
 */
export function getWeather(bigcode) {
    return request.post('/api/WisdomScreen/GetWeather', '', 'json', {
        bigcode: bigcode
    });
}

/**
 * 获取PM2.5、雨量的实时数据
 * @param {*} bigcode 
 * @returns 
 */
export function getYADeviceData(bigcode) {
    return request.post('/api/WisdomScreen/YADeviceData', '', 'json', {
        bigcode: bigcode
    });
}