<template>
  <div class="ray-border-box1">
    <div class="ray-box-title">设备在线率</div>
    <div class="main-right-container" style="height: 100%">
      <div style="display: flex; flex-direction: column; height: 100%">
        <div style="height: 30%; display: flex; align-items: center">
          <chert-pie
            id="testchart"
            :value="deviceTotalNum"
            :total="deviceTotalNum"
            title="设备总数"
            style="width: 100px; height: 100px; margin: 0 auto"
          ></chert-pie>
          <chert-pie
            id="testchart1"
            :value="deviceOnlineNum"
            :total="deviceTotalNum"
            title="在线设备"
            style="width: 100px; height: 100px; margin: 0 auto"
          ></chert-pie>
          <chert-pie
            id="testchart2"
            :value="deviceOfflineNum"
            :total="deviceTotalNum"
            title="离线设备"
            style="width: 100px; height: 100px; margin: 0 auto"
          ></chert-pie>
        </div>
        <div style="height: 70%" ref="mainRightList">
          <div class="main-right-tabs">
            <div
              class="tab-item"
              :class="{ active: tabactive == 0 }"
              @click="onTabClick(0)"
            >
              监测点列表
            </div>
            <div
              class="tab-item"
              :class="{ active: tabactive == 1 }"
              @click="onTabClick(1)"
            >
              灾害点列表
            </div>
          </div>
          <div class="raydata-warning-list" v-if="tabactive == 0">
            <div class="warning-list-head">
              <div class="head-row center" style="flex: 1; padding-left: 12px">
                灾害点
              </div>
              <div class="head-row center" style="width: 80px">预警等级</div>
              <div class="head-row center" style="width: 100px">预警次数</div>
            </div>
            <scroll :height="warningHeight">
              <div class="warning-list-body">
                <div
                  class="warning-list-tr"
                  v-for="(item, index) in (this.deviceData?this.deviceData.monitor:[])"
                  :key="index"
                >
                  <div
                    class="list-td hidden"
                    style="flex: 1; padding-left: 12px"
                  >
                    {{item.title}}
                  </div>
                  <div class="list-td center" style="width: 80px">{{item.level?item.level:'-'}}</div>
                  <div class="list-td center" style="width: 100px">
                    {{ item.count }}
                  </div>
                </div>
              </div>
            </scroll>
          </div>
          <div class="raydata-warning-list" v-if="tabactive == 1">
            <div class="warning-list-head">
              <div class="head-row center" style="flex: 1; padding-left: 12px">
                灾害点
              </div>
              <div class="head-row center" style="width: 80px">预警等级</div>
              <div class="head-row center" style="width: 100px">预警次数</div>
            </div>
            <scroll :height="warningHeight">
              <div class="warning-list-body">
                <div
                  class="warning-list-tr"
                  v-for="(item, index) in  (this.deviceData?this.deviceData.hiddanger:[])"
                  :key="index"
                >
                  <div
                    class="list-td hidden"
                    style="flex: 1; padding-left: 12px"
                  >
                    {{ item.title }}
                  </div>
                  <div class="list-td center" style="width: 80px">{{item.level}}</div>
                  <div class="list-td center" style="width: 100px">
                    {{ item.count }}
                  </div>
                </div>
              </div>
            </scroll>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.main-right-container {
  padding: 0 30px 0 30px;
}
.main-right-tabs {
  display: flex;
  background: rgba(0, 50, 113, 0.6);
  box-shadow: 0px 0px 4px 0px rgba(0, 165, 254, 0.59),
    0px 0px 15px 0px rgba(0, 165, 254, 0.3);
  border: 1px solid rgba(32, 254, 253, 0.2);
  .tab-item {
    flex: 1;
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
    &.active {
      font-weight: bold;
    }
    + .tab-item {
      position: relative;
      &::before {
        content: " ";
        position: absolute;
        left: 0;
        top: 20%;
        bottom: 20%;
        width: 1px;
        background: rgba(32, 254, 253, 0.35);
      }
    }
  }
}
.warning-list-head {
  border: none;
  .head-row {
    color: #fff;
  }
}
.warning-list-body {
  .warning-list-tr {
    border: none;
  }
  .warning-list-tr:nth-of-type(odd) {
    background: rgba(0, 50, 113, 0.6);
  }
}
</style>
<script>
import scroll from "@/components/scroll";
import ChertPie from "./chart-pie";

export default {
  data() {
    return {
      warningHeight: 10,
      tabactive: 0,
    };
  },
  computed:{
    deviceTotalNum:function(){
      if(this.deviceData){
        return this.deviceData.count.fcount+this.deviceData.count.ocount;
      }
      return 0;
    },
    deviceOnlineNum:function(){
      if(this.deviceData){
        return this.deviceData.count.ocount;
      }
      return 0;
    },
    deviceOfflineNum:function(){
      if(this.deviceData){
        return this.deviceData.count.fcount;
      }
      return 0;
    }
  },
  props: {
    bigcode: {
      type: String,
    },
    deviceData: {
      type: [String,Object],
    },
  },
  components: {
    scroll,
    ChertPie,
  },
  mounted() {
    this.warningHeight = this.$refs.mainRightList.clientHeight - 120;
    setInterval(() => {
      if(this.tabactive==0){
        this.tabactive = 1;
      }else{
        this.tabactive = 0;
      }
    }, 5000);
  },
  methods: {
    onTabClick(index) {
      this.tabactive = index;
    },
  },
};
</script>