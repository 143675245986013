<template>
  <div></div>
</template>
<script>
var echarts = require("echarts/lib/echarts");
// 引入柱状图
require("echarts/lib/chart/bar");
require("echarts/lib/chart/line");
// 引入提示框和标题组件
require("echarts/lib/component/tooltip");
require("echarts/lib/component/legend");
require("echarts/lib/component/title");
require("echarts/lib/component/grid");
require("echarts/lib/component/dataZoom");

export default {
  props: {
    data: {
      type: Array,
    }
  },
  methods: {
    initChart() {
      var datacount = [];
      var datawaring = [];
      var xAxisData = [];
      this.data.forEach(element => {
        datacount.push(element.count);
        datawaring.push(element.count);
        xAxisData.push(element.months);
      });

      const sideData = datacount.map((item) => item);

      var option = {
        color: [
          "#ff9e17",
          "#20fefd",
          "#00a5fe",
          "#EAEA26",
          "#906BF9",
          "#FE5656",
          "#01E17E",
          "#3DD1F9",
          "#FFAD05",
        ],
        grid: {
          top: "10%",
          left: "40",
          right: "20%",
          bottom: "20%",
          show: true,
          backgroundColor: "rgba(5, 62, 129, .6)",
          borderWidth: 0,
        },
        tooltip: {
          trigger: "axis",
          formatter: "{b} : {c}",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        legend: {
          top: 10,
          left: "right",
          orient: "vertical",
          icon: "circle",
          itemWidth: 10,
          align:'left',
          textStyle: {
            fontSize: 11,
            color: '#DBDBDB',
          },
        },
        xAxis: {
          data: xAxisData,
          //坐标轴
          axisLine: {
            lineStyle: {
              color: "#3eb2e8",
            },
          },
          //坐标值标注
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff",
            },
          },
          //分格线
          splitLine: {
            show: true,
            lineStyle: {
              color: "#165187",
            },
          },
        },
        yAxis: {
          //坐标轴
          axisLine: {
            show: false,
          },
          //坐标值标注
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff",
            },
          },
          //分格线
          splitLine: {
            lineStyle: {
              color: "#165187",
            },
          },
        },
        series: [
          {
            name: "数据回传量",
            tooltip: {
              show: false,
            },
            type: "bar",
            barWidth: 24.5,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  1,
                  0,
                  0,
                  [
                    {
                      offset: 0,
                      color: "#0B4EC3", // 0% 处的颜色
                    },
                    {
                      offset: 0.6,
                      color: "#138CEB", // 60% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#17AAFE", // 100% 处的颜色
                    },
                  ],
                  false
                ),
              },
            },
            data: datacount,
            barGap: 0,
          },
          {
            name: "数据回传量",//右边
            type: "bar",
            barWidth: 8,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  1,
                  0,
                  0,
                  [
                    {
                      offset: 0,
                      color: "#09337C", // 0% 处的颜色
                    },
                    {
                      offset: 0.6,
                      color: "#0761C0", // 60% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#0575DE", // 100% 处的颜色
                    },
                  ],
                  false
                ),
              },
            },
            barGap: 0,
            data: sideData,
          },
          {
            name: "数据回传量",//顶部
            tooltip: {
              show: false,
            },
            type: "pictorialBar",
            itemStyle: {
              borderWidth: 1,
              borderColor: "#0571D5",
              color: "#1779E0",
            },
            symbol: "path://M 0,0 l 120,0 l -30,60 l -120,0 z",
            symbolSize: ["32", "5"],
            symbolOffset: ["0", "-5"],
            //symbolRotate: -5,
            symbolPosition: "end",
            data: datacount,
            z: 3,
          },
          {
              name: "预警走势",
              type: 'line',
              data: datawaring,
          },
        ],
      };

      var chartObj = echarts.init(document.getElementById(this.$el.id));
      chartObj.clear();
      chartObj.setOption(option);
    },
  },
  mounted() {
    this.initChart();
  },
  watch: {
    data: function () {
      this.initChart();
    },
  },
};
</script>