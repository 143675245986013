<template>
  <div></div>
</template>
<script>
var echarts = require("echarts/lib/echarts");
// 引入柱状图
require("echarts/lib/chart/gauge");
require("echarts/lib/chart/pie");
// 引入提示框和标题组件
require("echarts/lib/component/tooltip");
require("echarts/lib/component/legend");
require("echarts/lib/component/title");
require("echarts/lib/component/grid");
require("echarts/lib/component/dataZoom");

export default {
  props: {
    value: {
      type: Number,
      defalut: 0,
    },
    title: {
      type: String,
      defalut: '',
    },
    color: {
      type: String,
      defalut: '#20FEFD',
    },
  },
  methods: {
    initChart() {
        var color = this.color ? this.color : '#20FEFD';
      var option = {
        title:{
            text:this.title,
            top:28,
            left:'45%',
            itemGap: 5,
            textAlign:'center',
            textStyle:{
                color:color,
                fontSize: 13,
                fontWeight: 'normal',
                opacity:0.7
            },
            subtext:this.value+"",
            subtextStyle:{
                color:color,
                fontSize: 24,
                fontWeight: 'bold'
            },
        },
        tooltip: {
          show: false,
        },
        toolbox: {
          show: false,
        },
        series: [
          {
            type: "pie",
            radius: ["68%", "70%"],
            hoverAnimation: false,
            clockWise: true,
            itemStyle: {
              normal: {
                color: color,
                opacity:0.6
              },
            },
            label: {
              show: false,
            },
            data: [100],
          },{
            type: "pie",
            radius: ["75%", "90%"],
            hoverAnimation: false,
            clockWise: true,
            itemStyle: {
              normal: {
                color: color,
                opacity:0.85
              },
            },
            label: {
              show: false,
            },
            data: [100],
          },{
            type: "pie",
            radius: ["95%", "97%"],
            hoverAnimation: false,
            clockWise: true,
            itemStyle: {
              normal: {
                color: color,
                opacity:0.6
              },
            },
            label: {
              show: false,
            },
            data: [100],
          },
        ],
      };

      var chartObj = echarts.init(document.getElementById(this.$el.id));
      chartObj.clear();
      chartObj.setOption(option);
    },
  },
  mounted() {
    this.initChart();
  },
  watch:{
      value:function(){
          this.initChart();
      }
  }
};
</script>