<template>
    <div></div>
</template>
<script>
var echarts = require("echarts/lib/echarts");
// 引入柱状图
require("echarts/lib/chart/gauge");
require("echarts/lib/chart/pie");
// 引入提示框和标题组件
require("echarts/lib/component/tooltip");
require("echarts/lib/component/legend");
require("echarts/lib/component/legendScroll");
require("echarts/lib/component/title");
require("echarts/lib/component/grid");
require("echarts/lib/component/dataZoom");

export default {
    data() {
        return {
            timeInterval: null,
        };
    },
    props: {
        data: {
            type: Array,
        },
    },
    methods: {
        initChart() {
            var data = this.data;

            var color = [
                "#20fefd",
                "#00a5fe",
                "#ff9e17",
                "#EAEA26",
                "#906BF9",
                "#FE5656",
                "#01E17E",
                "#3DD1F9",
                "#FFAD05",
            ];
            var legendData = [];
            var pieData = [];
            data.forEach((item, index) => {
                legendData.push({
                    name: item.name,
                    textStyle: {
                        color: color[index],
                    },
                });
                pieData.push({
                    name: item.name,
                    value: item.value,
                    label: {
                        color: color[index],
                    },
                });
            });
            var option = {
                color: color,
                legend: {
                    show:false,
                    type: "scroll",
                    right: '10px',
                    icon: "circle",
                    align: "right",
                    orient: "vertical",
                    itemWidth: 8,
                    pageIconColor:'#43FFFF',
                    pageTextStyle:{
                        color:'#43FFFF'
                    },
                    pageIconSize:10,
                    data: legendData,
                    textStyle: {
                        fontSize: 12,
                    },
                },
                series: [
                    {
                        type: "pie",
                        radius: ["18%", "23%"],
                        center: ["50%", "45%"],
                        hoverAnimation: false,
                        clockWise: true,
                        itemStyle: {
                            normal: {
                                color: "#127394",
                                opacity: 0.85,
                            },
                        },
                        label: {
                            show: false,
                        },
                        data: [100],
                    },
                    {
                        type: "pie",
                        radius: ["30%", "65%"],
                        center: ["50%", "45%"],
                        roseType: "area",
                        data: pieData,
                        label: {
                            formatter: "{b}\n{d}%",
                        },
                    },
                ],
            };

            var chartObj = echarts.init(document.getElementById(this.$el.id));
            chartObj.clear();
            chartObj.setOption(option);
        },
    },
    mounted() {
        this.initChart();
        if (this.timeInterval) {
            clearInterval(this.timeInterval);
        }
        this.timeInterval = setInterval(() => {
            this.initChart();
        }, 12000);
    },
    watch: {
        data: {
            deep: true,
            handler() {
                this.initChart();
            },
        },
    },
};
</script>