<template>
  <div class="ray-border-box2">
    <div class="ray-box-title">地图展示</div>
    <chart-map
      id="chartChinaMap"
      :data="mapData"
      :center="mapCenter"
      :map-area="initData.AreaFile"
      style="height: 100%; width: 100%"
    ></chart-map>
  </div>
</template>
<style lang="scss">
</style>
<script>
import chartMap from "./chart-map";
export default {
  data() {
    return {
      mapCenter: {
        name: "",
        value: [],
      },
      mapData: [
        // {
        //   name: "供应商A",
        //   value: [104.06, 30.67],
        // },
        // {
        //   name: "供应商B",
        //   value: [109.5, 34.52],
        // },
      ],
    };
  },
  props: {
    deviceData: Object,
    initData: [String, Object],
  },
  components: {
    chartMap,
  },
  watch: {
    initData: {
      deep: true,
      handler() {
        this.mapCenter = {
          name: "",
          value: [this.initData.Longitude, this.initData.Latitude],
        };
      },
    },
    deviceData: {
      deep: true,
      handler() {
        var points = [];
        this.deviceData.hiddanger.forEach((element) => {
          points.push({
            name: element.title,
            value: [element.mlong, element.mlat],
          });
        });
        this.mapData = points;
      },
    },
  },
};
</script>