<template>
    <div class="scroll-container" :ref="'scrollContainer'+childId">
        <div class="scroll-container-body" :ref="'scrollContainerBody'+childId">
            <div class="scroll-content" :ref="'scrollContainerBodyContent'+childId" >
                <slot></slot>
            </div>
            <div class="scroll-content" v-if="iscontinuity">
                <slot></slot>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.scroll-container{
    overflow: hidden;
    height: 0;
}
</style>
<script>
export default {
    data(){
        return {
            childId:'',
            iscontinuity:false,
        }
    },
    props:{
        time:{
            type:Number,
            default: 50
        },
        continuity:{
            type:Boolean,
            default:true,
        },
        height:{
            type:Number,
            default:0,
        },
        scrollHeight:{
            type:Number,
            default:0,
        }
    },
    mounted(){
        var that = this,initTop = 0,scrollVector = 1;
        var moveTarget = this.$refs['scrollContainerBody'+this.childId];
        var contentHeight = this.$refs['scrollContainerBodyContent'+this.childId].clientHeight;
        if(this.height>0){
            this.$refs['scrollContainer'+this.childId].style="height:"+this.height+"px";
            if(contentHeight<this.height){
                this.iscontinuity = false;
            }
        }else{
            this.$refs['scrollContainer'+this.childId].style="height:"+contentHeight+"px";
        }
        if(this.scrollHeight>0){
            scrollVector = this.scrollHeight;
        }
        if(this.$refs['scrollContainer'+this.childId].clientHeight>contentHeight){
            return;
        }
        setInterval(function(){
            contentHeight = that.$refs['scrollContainerBodyContent'+that.childId].clientHeight;
            initTop = initTop - scrollVector;
            if(initTop*-1>contentHeight){
                if(that.iscontinuity){
                    initTop = 0;
                }else{
                    initTop = that.height>0 ? that.height : contentHeight;
                }
            }
            moveTarget.style = 'transform: translateY('+ (initTop) +'px)';
        },this.time)
    },
    methods:{
        getRandomId(num){
            var returnStr = "",       
                charStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'; 
            for(var i=0; i<num; i++){
                var index = Math.round(Math.random() * (charStr.length-1));
                returnStr += charStr.substring(index,index+1);
            }
            return returnStr;
        }
    },
    created(){
        this.iscontinuity = this.continuity;
        this.childId = this.getRandomId(10);
    },
    watch:{
        height:function(){
            if(this.height>0){
                this.$refs['scrollContainer'+this.childId].style="height:"+this.height+"px";
            }else{
                this.$refs['scrollContainer'+this.childId].style="height:"+contentHeight+"px";
            }
        }
    }
}
</script>