<template>
    <div class="ray-border-box1">
        <div class="ray-box-title">信息列表</div>
        <div class="main-left-container" style="height:calc(100% - 20px);">
            <div style=" flex-direction: column; height: 100%">
                <div style="height:calc(35% - 10px);margin-bottom:10px;">
                    <div class="main-left-hdtitle">{{ shwoNotice ? '基本信息' : '实时数据' }}</div>
                    <div class="main-left-body" style="
                            padding: 10px;
                            height: calc(100% - 28px);
                            line-height: 1.6;
                            overflow: hidden;
                        " ref="mainLeftBaseinfo" v-if="shwoNotice">
                        <div ref="mainLeftBaseinfoContent" v-if="!baseScroll">
                            {{ notice ? notice : "暂无信息" }}
                        </div>
                        <scroll v-else :height="baseinfoHeight" :time="50" :scroll-height="0.5">
                            {{ notice ? notice : "暂无信息" }}
                        </scroll>
                    </div>
                    <div class="main-left-body main-left-body-data" style="
                            padding: 10px;
                            height: calc(100% - 28px);
                            line-height: 1.6;
                            overflow: hidden;
                        " v-else>
                        <el-row>
                            <el-col :span="12">
                                <div class="data-item-value">{{ yADeviceData ? yADeviceData.pm25 : '0' }}ug/m3</div>
                                <div class="data-item-label">PM2.5</div>
                            </el-col>
                            <el-col :span="12">
                                <div class="data-item-value">{{ yADeviceData ? yADeviceData.yl : '0' }}mm</div>
                                <div class="data-item-label">雨量</div>
                            </el-col>
                        </el-row>
                    </div>
                </div>
                <div style="height: 65%;" ref="mainLeftWarningList">
                    <div class="main-left-hdtitle">预警信息</div>
                    <div class="main-left-body">
                        <div class="raydata-warning-list">
                            <div class="warning-list-head">
                                <div class="head-row" style="flex: 1; padding-left: 12px">
                                    监测点
                                </div>
                                <div class="head-row center" style="width: 33%;">
                                    预警等级
                                </div>
                                <div class="head-row center" style="width: 33%;">
                                    预警时间
                                </div>
                            </div>
                            <scroll :height="warningHeight">
                                <div class="warning-list-body">
                                    <div class="warning-list-tr" v-for="(item, index) in waringData" :key="index">
                                        <div class="list-td hidden" style="flex: 1; padding-left: 12px">
                                            {{ item.mtitle }}
                                        </div>
                                        <div class="list-td center" style="width: 33%;">
                                            {{ item.level | warningText }}
                                        </div>
                                        <div class="list-td hidden" style="width: 33%">
                                            {{ item.time }}
                                        </div>
                                    </div>
                                </div>
                            </scroll>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.main-left-container {
    padding: 20px;

}

.main-left-hdtitle {
    background: url("~@/assets/raydata/main-left-hdbg.png") left center no-repeat;
    background-size: cover;
    padding: 6px 12px;
}

.main-left-body {
    background: rgba(0, 50, 113, 0.6);
    box-shadow: 0px 0px 4px 0px rgba(0, 165, 254, 0.59),
        0px 0px 15px 0px rgba(0, 165, 254, 0.3);
    border: 1px solid rgba(32, 254, 253, 0.2);
    color: #e6e0e0;
    font-size: 13px;
}

.main-left-body-data {
    display: flex;
    align-items: center;
    justify-content: center;

    .el-row {
        flex: 1;
    }
}

.data-item-label {
    font-size: 16px;
    text-align: center;
    color: #20FEFD;
}

.data-item-value {
    font-size: 28px;
    font-weight: 700;
    text-align: center;
}
</style>
<script>
import scroll from "@/components/scroll";
export default {
    data() {
        return {
            datalist: [],
            warningHeight: 10,
            baseinfoHeight: 0,
            baseScroll: false,
        };
    },
    props: {
        bigcode: {
            type: String,
        },
        notice: {
            type: String,
            default: "",
        },
        shwoNotice: {
            type: Boolean,
            default: true,
        },
        waringData: {
            type: [String, Array],
        },
        yADeviceData: Object,
    },
    components: {
        scroll,
    },
    mounted() {
        this.warningHeight = this.$refs.mainLeftWarningList.clientHeight - 80;
        this.baseinfoHeight = this.$refs.mainLeftBaseinfo.clientHeight;
    },
    methods: {},
    watch: {
        notice() {
            this.warningHeight =
                this.$refs.mainLeftWarningList.clientHeight - 80;
            setTimeout(() => {
                //this.baseinfoHeight = this.$refs.mainLeftBaseinfo.clientHeight;
                if (
                    this.baseinfoHeight <
                    this.$refs.mainLeftBaseinfoContent.clientHeight
                ) {
                    this.baseScroll = true;
                } else {
                    this.baseScroll = false;
                }
            }, 50);
        },
    },
    filters: {
        warningText(value) {
            if (value == 1) {
                return "注意级";
            } else if (value == 2) {
                return "警示级";
            } else if (value == 3) {
                return "警戒级";
            } else if (value == 4) {
                return "警报级";
            }
        }
    },
};
</script>