<template>
  <div class="ray-border-box3">
    <el-row class="row-block" :gutter="20">
      <el-col class="row-block" :span="12">
        <div class="ray-box-title">区域占比</div>
        <div style="height:100%;">
          <chart-progress id="chartprogress" :data="regionDeviceList" style="width:100%;height:100%;"></chart-progress>
        </div>
      </el-col>
      <el-col class="row-block" :span="12">
        <div class="ray-box-title">预警数据量</div>
        <div style="height: 90%; display:flex;align-items:center;justify-content:space-around;">
          <!-- <chart-pie-warning id="chartddddd" style="height:100px;width:100px;" color="#32C5FF" :value="waringData?waringData[0].count:0" title="注意级"></chart-pie-warning> -->
          <chart-pie-warning id="chartddddd2" style="height:100px;width:100px;" color="#fbca29" :value="jsValue" title="警示级"></chart-pie-warning>
          <chart-pie-warning id="chartddddd3" style="height:100px;width:100px;" color="#c85554" :value="jjValue" title="警戒级"></chart-pie-warning>
          <chart-pie-warning id="chartddddd4" style="height:100px;width:100px;" color="#FF3B20" :value="jbValue" title="警报级"></chart-pie-warning>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<style lang="scss">
</style>
<script>
import chartProgress from './chart-progress'
import chartPieWarning from './chart-pie-warning'
export default {
  data(){
    return {
      index:0,
      regionDeviceList:[],
    }
  },
  computed:{
      jsValue(){
          if(this.waringData){
              var selected = this.waringData.filter(item=>item.level==2);
              if(selected.length>0){
                  return selected[0].count;
              }
          }
          return 0;
      },
      jjValue(){
          if(this.waringData){
              var selected = this.waringData.filter(item=>item.level==3);
              if(selected.length>0){
                  return selected[0].count;
              }
          }
          return 0;
      },
      jbValue(){
          if(this.waringData){
              var selected = this.waringData.filter(item=>item.level==4);
              if(selected.length>0){
                  return selected[0].count;
              }
          }
          return 0;
      },
  },
  components:{
    chartProgress,
    chartPieWarning
  },
  props: {
    bigcode: {
      type: String,
    },
    deviceData: {
      type: [String,Object],
    },
    waringData:{
      type: [String,Array],
    },
  },
  mounted(){
    setInterval(() => {
      if(this.deviceData){
        var listdata = this.deviceData.hiddanger[this.index].deviceList;
        var deviceList = [];
        listdata.forEach(element => {
          deviceList.push({
            name:element.title,
            value:element.count,
          })
        });
        this.regionDeviceList = deviceList;
        this.index = this.index+1;
        if(this.index>=this.deviceData.hiddanger.length){
          this.index = 0;
        }
      }
    }, 8000);
  },
  methods: {
  },
  watch:{
    deviceData:{
      deep:true,
      handler(){
        this.index = 0;
        var listdata = this.deviceData.hiddanger[this.index].deviceList;
        var deviceList = [];
        listdata.forEach(element => {
          deviceList.push({
            name:element.title,
            value:element.count,
          })
        });
        this.regionDeviceList = deviceList;
      }
    }
  }
};
</script>