<template>
  <div></div>
</template>
<script>
var echarts = require("echarts/lib/echarts");
// 引入柱状图
require("echarts/lib/chart/bar");
require("echarts/lib/chart/pictorialBar");
// 引入提示框和标题组件
require("echarts/lib/component/tooltip");
require("echarts/lib/component/legend");
require("echarts/lib/component/title");
require("echarts/lib/component/grid");
require("echarts/lib/component/dataZoom");
export default {
  props: {
    data:Array
  },
  methods: {
    initChart() {
      var category = [
        {
          name: "裂缝计",
          value: 45,
        },
        {
          name: "雨量计",
          value: 43,
          color:'#FF9E17',
          bgcolor:'#3d3d3d'
        },
        {
          name: "含水率",
          value: 123,
        },
      ]; // 类别
      category = this.data;
      var color = [
        "#20fefd",
        "#00a5fe",
        "#ff9e17",
        "#EAEA26",
        "#906BF9",
        "#FE5656",
        "#01E17E",
        "#3DD1F9",
        "#FFAD05",
      ];
      var total = 0;// 数据总数
      category.forEach((item) => {
        total += item.value
      })
      var datas = [];
      var bgdatas = [];
      var bardatas = [];
      category.forEach((item,index) => {
        datas.push(item.value);
        bardatas.push({
            name: item.name,
            value: item.value,
            itemStyle:{
                color:color[index],//item.color? item.color:'#1588D1'
            }
        })
        bgdatas.push({
            value: total,
            itemStyle:{
                color:item.bgcolor ? item.bgcolor : '#07314a'
            }
        })
        
      });
      var option = {
        xAxis: {
          max: total,
          splitLine: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        grid: {
          left: 80,
          top: 30, // 设置条形图的边距
          right: 80,
          bottom: 30,
        },
        yAxis: [
          {
            type: "category",
            inverse: false,
            data: category,
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
          },
        ],
        series: [
          {
            // 内
            type: "bar",
            barWidth: 10,
            silent: true,
            itemStyle: {
              normal: {
                color: "#1588D1",
              },
            },
            label: {
              normal: {
                formatter: "{b}",
                textStyle: {
                  color: "#fff",
                  fontSize: 14,
                },
                position: "left",
                distance: 10, // 向右偏移位置
                show: true,
              },
            },
            data: bardatas,
            z: 1,
            animationEasing: "elasticOut",
          },

          {
            // 分隔
            type: "pictorialBar",
            itemStyle: {
              normal: {
                color: "#07314a",
              },
            },
            symbolRepeat: "fixed",
            symbolMargin: 1,
            symbol: "rect",
            symbolClip: true,
            symbolSize: [2, 10],
            symbolPosition: "start",
            symbolOffset: [2, 0],
            symbolBoundingData: this.total,
            data: bgdatas,
            z: 2,
            animationEasing: "elasticOut",
          },
          {
            // label
            type: "pictorialBar",
            symbolBoundingData: total,
            itemStyle: {
              normal: {
                color: "none",
              },
            },
            label: {
              normal: {
                formatter: "{f|{c}个}",
                rich: {
                  f: {
                    color: "#cccccc",
                  },
                },
                position: "right",
                distance: 10, // 向右偏移位置
                show: true,
              },
            },
            data: datas,
            z: 0,
          },
        ],
      };

      var chartObj = echarts.init(document.getElementById(this.$el.id));
      chartObj.clear();
      chartObj.setOption(option);
    },
  },
  mounted() {
    this.initChart();
  },
  watch: {
    data: function () {
      this.initChart();
    },
  },
};
</script>