<template>
  <div></div>
</template>
<script>
var echarts = require("echarts/lib/echarts");
// 引入柱状图
require("echarts/lib/chart/gauge");
require("echarts/lib/chart/pie");
// 引入提示框和标题组件
require("echarts/lib/component/tooltip");
require("echarts/lib/component/legend");
require("echarts/lib/component/title");
require("echarts/lib/component/grid");
require("echarts/lib/component/dataZoom");

export default {
  props: {
    value: {
      type: Number,
      defalut: 0,
    },
    total:{
        type:Number,
        defalut: 100,
    },
    title: {
      type: String,
      defalut: '',
    },
  },
  methods: {
    initChart() {
      var value = this.value;
      var total = this.total;
      function bgData() {
        let dataArr = [];
        for (var i = 0; i < 100; i++) {
          if (i % 4 === 0) {
            dataArr.push({
              name: (i + 1).toString(),
              value: 100,
              itemStyle: {
                normal: {
                  color: "rgba(32, 254, 253, 0.2)",
                  borderWidth: 0,
                  borderColor: "rgba(0,0,0,0)",
                },
              },
            });
          } else {
            dataArr.push({
              name: (i + 1).toString(),
              value: 5,
              itemStyle: {
                normal: {
                  color: "rgba(0,0,0,0)",
                  borderWidth: 0,
                  borderColor: "rgba(0,0,0,0)",
                },
              },
            });
          }
        }
        return dataArr;
      }

      function rateData() {
        let dataArr = [];
        for (var i = 0; i < 100; i++) {
          if (i % 4 === 0) {
            dataArr.push({
              name: (i + 1).toString(),
              value: 500,
              itemStyle: {
                normal: {
                  color: i < (value/total*100) ? "#20FEFD" : "rgba(0,0,0,0)",
                  borderWidth: 0,
                  borderColor: "rgba(0,0,0,0)",
                },
              },
            });
          } else {
            dataArr.push({
              name: (i + 1).toString(),
              value: 20,
              itemStyle: {
                normal: {
                  color: "rgba(0,0,0,0)",
                  borderWidth: 0,
                  borderColor: "rgba(0,0,0,0)",
                },
              },
            });
          }
        }
        return dataArr;
      }

      const color = ["#008BFF", "#DE496B"];

      var option = {
        color,
        title:{
            text:this.value,
            top:25,
            left:'45%',
            itemGap: 5,
            textAlign:'center',
            textStyle:{
                color:'#20FEFD',
                fontSize: 22,
            },
            subtext:this.title,
            subtextStyle:{
                color:'#20FEFD',
                fontSize: 11,
            },
        },
        tooltip: {
          show: false,
        },
        toolbox: {
          show: false,
        },
        series: [
          {
            type: "pie",
            zlevel: 3,
            silent: true,
            radius: ["80%", "100%"],
            label: {
              normal: {
                show: false,
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: bgData(),
          },
          {
            type: "pie",
            zlevel: 3,
            silent: true,
            radius: ["80%", "100%"],
            label: {
              normal: {
                show: false,
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: rateData(),
          },
          {
            type: "pie",
            name: "内层细圆环",
            radius: ["68%", "70%"],
            hoverAnimation: false,
            clockWise: true,
            itemStyle: {
              normal: {
                color: "#20FEFD",
              },
            },
            label: {
              show: false,
            },
            data: [100],
          },
        ],
      };

      var chartObj = echarts.init(document.getElementById(this.$el.id));
      chartObj.clear();
      chartObj.setOption(option);
    },
  },
  mounted() {
    this.initChart();
  },
  watch:{
      value:function(){
          this.initChart();
      }
  }
};
</script>